@keyframes slideLeft {
  0% {
    transform: translateX(+100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-logo {
  animation: slideLeft 60s linear infinite; /* Adjust duration as needed */
}
