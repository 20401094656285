@import url('https://fonts.googleapis.com/css?family=Roboto:300');

#animation_body {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

#escape {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

#escape {
  animation: showup 7s infinite;
}

#experiences {
  animation: reveal 7s infinite;
}

#experiences span {
  position: absolute;
  animation: slidein 7s infinite;
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slidein {
  0% {
    width: 400px;
    margin-left: 2200px;
  }
  20% {
    width: 400px;

    margin-left: 2200px;
  }
  35% {
    width: 400px;

    margin-left: -200px;
  }
  100% {
    width: 400px;

    margin-left: -200px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animate-pulse {
  animation: pulse 7s infinite; /* Adjust the duration (2s) as needed */
}

.nav-links:hover {
  border-bottom: 4px solid #000738;
  color: red;
  transition: all 0.2s ease-in;
}
